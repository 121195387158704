<template>
    <carousel :settings="slideSettings">
        <slide :key="1">
            <div class="main-forex-header-slider__slide main-forex-header-slider__slide_type_1">
                <div class="main-forex-header-slider__title">{{ gettext('tpl:pages:forex:main:slide_1_title') }}</div>
                <div class="main-forex-header-slider__desc">{{ gettext('tpl:pages:forex:main:slide_1_desc') }}</div>
                <div class="main-forex-header-slider__buttons">
                    <a :href="pathByLang('/forex?new_acc=real')" class="f-btn f-btn-lg f-btn-dark">{{ gettext('tpl:pages:forex:main:slide_1_link_1') }}</a>
                    <a :href="pathByLang('/forex?new_acc=demo')" class="f-btn f-btn-lg f-btn-light">{{ gettext('tpl:pages:forex:main:slide_1_link_2') }}</a>
                </div>
            </div>
        </slide>
        <slide :key="2" v-if="isNoDepositEnabled">
            <div class="main-forex-header-slider__slide main-forex-header-slider__slide_type_2">
                <div class="main-forex-header-slider__title">{{ gettext('tpl:pages:forex:main:slide_2_title') }}</div>
                <div class="main-forex-header-slider__desc">{{ gettext('tpl:pages:forex:main:slide_2_desc') }}</div>
                <div class="main-forex-header-slider__buttons">
                    <a :href="pathByLang('/no_deposit_bonus')" class="f-btn f-btn-lg f-btn-dark">{{ gettext('tpl:pages:forex:main:slide_2_link') }}</a>
                </div>
            </div>
        </slide>

        <template v-if="isNoDepositEnabled" #addons>
            <pagination />
        </template>
    </carousel>
</template>


<script>
import { Carousel, Slide, Pagination } from 'vue3-carousel'

export default {
    name: 'main-header-slider',

    components: {
        Carousel,
        Slide,
        Pagination,
    },

    data() {
        return {
            isNoDepositEnabled: jsParams().is_no_deposit_bonus_enabled,
            slideSettings: {
                itemsToShow: 1,
                autoplay: this.isNoDepositEnabled ? 7000 : 0,
                wrapAround: this.isNoDepositEnabled,
            }
        };
    },

    computed: {
    },

    methods: {
    },

    mounted() {
    },
};
</script>
